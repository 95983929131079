
// angular notifier
@use '@angular/material' as mat;
@import "~angular-notifier/styles";

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

:root {
  --primary-color-hover: 'white';
  --primary-color-selected: 'white';
  --black-inactive: #d3d3d554;
  --black-disabled: #0000008a;
  --warn-color: #FFC107;
  --success-color: #00C853;
  --font-family-primary: 'Kufam';
}

$ng-primary: var(--primary-color);
$ng-primary-selected: var(--primary-color-selected);
$ng-primary-hover: var(--primary-color-hover);
$ng-black-inactive: var(--black-inactive);
$ng-black-disabled: var(--black-disabled);
$ng-success: var(--success-color);
$ng-warn: var(--warn-color);

$ng-black-inactive-palette: (
        50: var(--black-inactive),
        100: var(--black-inactive),
        200: var(--black-inactive),
        300: var(--black-inactive),
        400: var(--black-inactive),
        500: var(--black-inactive),
        600: var(--black-inactive),
        700: var(--black-inactive),
        800: var(--black-inactive),
        900: var(--black-inactive),
        A100: var(--black-inactive),
        A200:var(--black-inactive),
        A400: var(--black-inactive),
        A700: var(--black-inactive),
        contrast: (
                50: var(--black-inactive),
                100: var(--black-inactive),
                200: var(--black-inactive),
                300: var(--black-inactive),
                400: var(--black-inactive),
                500: var(--black-inactive),
                600: var(--black-inactive),
                700: var(--black-inactive),
                800: var(--black-inactive),
                900: var(--black-inactive),
                A100: var(--black-inactive),
                A200: var(--black-inactive),
                A400: var(--black-inactive),
                A700: var(--black-inactive),
        )
);


$ng-primary-palette: (
        50: $ng-primary,
        100: $ng-primary,
        200: $ng-primary,
        300: $ng-primary,
        400: $ng-primary,
        500: $ng-primary,
        600: $ng-primary,
        700: $ng-primary,
        800: $ng-primary,
        900: $ng-primary,
        A100: $ng-primary,
        A200: $ng-primary,
        A400: $ng-primary,
        A700: $ng-primary,
        contrast: (
                50: $dark-primary-text,
                100: $dark-primary-text,
                200: $dark-primary-text,
                300: $light-primary-text,
                400: $light-primary-text,
                500: $light-primary-text,
                600: $light-primary-text,
                700: $light-primary-text,
                800: $light-primary-text,
                900: $light-primary-text,
                A100: $dark-primary-text,
                A200: $light-primary-text,
                A400: $light-primary-text,
                A700: $light-primary-text,
        )
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ng-theme: mat.define-light-theme((
  color: (
          primary: mat.define-palette($ng-primary-palette),
          accent: mat.define-palette($ng-primary-palette),
          warn: mat.define-palette($ng-black-inactive-palette),
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ng-theme);

//*:not(mat-icon,.material-icons-outlined){
//  font-family: 'Kufam';
//}

html, body {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

.menu-overflow-hidden {
  overflow: hidden !important;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/materialicons/v134/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

/* fallback */
@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v106/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2) format('woff2');
}

/* fallback */
@font-face {
  font-family: 'Material Icons Round';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/materialiconsround/v105/LDItaoyNOAY6Uewc665JcIzCKsKc_M9flwmP.woff2) format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTXhCUOGz7vYGh680lGh-uXM.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSnhCUOGz7vYGh680lGh-uXM.woff) format('woff');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

.material-icons-round {
  font-family: 'Material Icons Round';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

.primary-color {
  color: var(--primary-color) !important;
}

